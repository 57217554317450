import { useEffect, useState } from "react";

function TheySaid() {
    const theySaid = [
        `
            „Olivér nagyon jól oktat, hasznos és kreatív feladatokat hoz,  személyreszabva, 
            akár saját példákat/ házifeladatokat is küldhettem neki amiket segített 
            megoldani és megérteni. - Évi”`,
        `
            „Szeretem ahogy oktat, mert gyorsan lehet haladni, viszont, 
            ha lemaradok megvár, és az nagyon tetszik, hogy akár két óra között is segít.” - Gabi
        `,
        `
            „Olivértől bármit lehet kérdezni, még nem volt olyan, amire ne tudott 
            volna válaszolni. Rendkívul türelmes es logikus magyarázatot ad mindenre.” - Anna
        `
    ];

    let [i, setI] = useState(-1);
    let [text, setText] = useState(theySaid[i]);
    let [transition, setTransition] = useState("transition");

    useEffect(()=> {
        if(i !== -1)
            setText(theySaid[i]);
        else 
            setText(theySaid[0]);

        setTimeout(()=> {
            if(i < theySaid.length-1) {
                setI((i) => i + 1);
            } else {
                setI(0);
            }
        }, 4000);
    }, [i]);

    return(
        <div className="they-said bg-secondary text-center p15 border-dark-grey">
            <h2>Rólam mondták</h2>

            <div id="they-said-text">
                {text}
            </div>
        </div>
    );
}

export default TheySaid;