import ContactLine from "./ContactLine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "./ContactForm";

function Contact() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="p15 col-lg-6 col-md-6 text-center">
                        <div className="box p15">
                            <h1>Kapcsolat</h1>

                            <div className="mb15">
                                <FontAwesomeIcon icon="fa-solid fa-mobile-screen" 
                                className="color-secondary"/> 0670 305 0928 
                            </div>
                            <div className="mb15">
                                <FontAwesomeIcon icon="fa-solid fa-square-envelope" 
                                className="color-secondary"/> kovacs.oliver1989@gmail.com
                            </div>

                            <div className="mb15">
                                <a className="color-secondary" href="https://www.facebook.com/tanuljkodolni" target="_blank">
                                    <FontAwesomeIcon icon="fa-brands fa-facebook" 
                                    className="color-secondary"/> Facebook
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="p15 col-lg-6 col-md-6 text-center">
                        <div className="box p15">
                            <h1>Üzenet küldése</h1>

                            <ContactForm subject={"Magánóra"}/>
                        </div>
                    </div>
                </div>
            </div>

            <ContactLine bgcolor="bg-dark-grey"/>
        </>
    );
}

export default Contact;