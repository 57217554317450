import { Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SelectMenu from "../app/SelectMenu";
import { createContext, useEffect, useState } from "react";
import ContactLine from "./ContactLine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const MbContext = createContext();

function Nav() {
    const location = useLocation();
    const marketing = ["Összhangban az elmélet és a gyakorlat", "Könnyen érthető magyarázatok", "Türelmes oktató"];
    let [i, setI] = useState(-1);
    let [j, setJ] = useState(0);
    let [currentMarketing, setCurrentMarketing] = useState("");
    let [tempMarketing, setTempMarketing] = useState("");
    let [char, setChar] = useState('');
    let [headerMini, setHeaderMini] = useState("");
    let [scrollY, setScrollY] = useState(window.scrollY);
    let [navClass, setNavClass] = useState("");
    const [messages, setMessages] = useState([]);
    const [displayMb, setDisplayMb] = useState(false);
    const [buttons, setButtons] = useState([{
        text: "OK",
        icon: "fa-solid fa-square-check",
        cb: () => setDisplayMb(false)
    }]);
    const [hideMenu, setHideMenu] = useState(false);

    useEffect(()=> {
        if(window.innerWidth <= 600) {
            setHideMenu(true);
        }
        window.addEventListener("scroll", ()=> {
            setScrollY(window.scrollY);
        });
    }, []);

    useEffect(()=> {
        setHideMenu(window.innerWidth <= 600);
    }, [window.innerWidth]);

    useEffect(()=> {
        if(i === -1)
            setChar(marketing[j][0]);
        else if(marketing[j][i])
            setChar(marketing[j][i]);

        setTimeout(() => {
            if(i < marketing[j].length-1) {
                setI((i) => i + 1);
            }
            else {
                setTempMarketing(currentMarketing + marketing[j][i]);
                setI(-10);
                setCurrentMarketing("");

                if(j < marketing.length-1) {
                    setJ((j)=>j+1);
                } else {
                    setJ(0);
                }
            }
        }, 120);
    }, [i, j]);

    useEffect(()=> {
        if(scrollY > 30) {
            setNavClass("nav-sticky");
        } else {
            setNavClass("");
        }
    }, [scrollY]);

    useEffect(()=> {
        setCurrentMarketing((currentMarketing)=>currentMarketing+char);
    }, [char]);

    useEffect(()=> {
        if(location.pathname !== "/")
            setHeaderMini("header-mini");
        else 
            setHeaderMini("");
    }, [location]);

    return(
        <>
            {<ContactLine bgcolor="bg-secondary"/>}

            <nav className={navClass}>
                <div className={"menu-icon " + (window.innerWidth > 600 && "display-none")}>
                    <FontAwesomeIcon onClick={()=>setHideMenu(!hideMenu)}
                    icon="fa-solid fa-bars" />
                </div>
                <ul className={hideMenu ? "display-none" : ""}>
                    <li className={SelectMenu(location.pathname, "/")}>
                        <Link onClick={()=>setHideMenu(true && window.innerWidth <= 600)} to="/">Rólam</Link>
                    </li>
                    <li className={SelectMenu(location.pathname, "/szolgaltatasaim")}>
                        <Link onClick={()=>setHideMenu(true && window.innerWidth <= 600)} to="/szolgaltatasaim">Szolgáltatásaim</Link>
                    </li>
                    <li className={SelectMenu(location.pathname, "/webfejlesztes-kurzus")}>
                        <Link onClick={()=>setHideMenu(true && window.innerWidth <= 600)} to="/webfejlesztes-kurzus">Webfejlesztés kurzus</Link>
                    </li>
                    <li className={SelectMenu(location.pathname, "/kapcsolat")}>
                        <Link onClick={()=>setHideMenu(true && window.innerWidth <= 600)} to="/kapcsolat">Kapcsolat</Link>
                    </li>
                </ul>
            </nav>
            <header className={headerMini + (!hideMenu && window.innerWidth <= 600 ? " display-none" : "")}>
                <div className="marketing">
                    <h3>{currentMarketing||tempMarketing}</h3>
                </div>
            </header>

            <MbContext.Provider value={{
                messages,
                setMessages,
                displayMb,
                setDisplayMb,
                buttons,
                setButtons
            }}>
                <Outlet/>
            </MbContext.Provider>
        </>
    );
}

export default Nav;