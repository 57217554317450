const protocol = window.location.protocol;
const clientHost = window.location.host;
const clientPort = window.location.port;
const serverHost = window.location.hostname;
const serverPort = window.location.hostname === "localhost" ? 3001 : "";
const cBaseUrl = `${protocol}//${clientHost}:${clientPort}`;
const sBaseUrl = `${protocol}//${serverHost}:${serverPort}`;

export {
    protocol, clientHost, 
    clientPort, serverHost, 
    serverPort, cBaseUrl, sBaseUrl
};