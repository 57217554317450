import ContactLine from "./ContactLine";
import Img from "./Img";
import ContactForm from "./ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function WebDevCourse() {
    return (
        <>
            <div className="container mobile-zero-padding">
                <div className="row">
                <div className="p15 col-lg-6 col-md-6">
                        <div className="box p15">
                            <h1>A képzésről</h1>
                            <p>
                                A kurzus a webfejlesztés alapjaitól indul, de életszerű
                                webes rendszerek fejlesztéséig is eljutunk. Rajtad múlik, hogy 
                                inkább a <a href="#frontend">frontend</a> vagy a <a href="#backend">backend</a> érdekel jobban,
                                esetleg mindkettő foglalkoztat. Az érdeklődésed szerint fogom kialakítani a tananyagot, 
                                de nem muszáj már az elején döntened. A backend fejlesztőnek is szüksége lehet némi
                                frontend tudásra, és fordítva.
                                <br/>
                                <i>(<a href="#elmelet">Kattints ide </a> az elméleti fogalmak megismeréséhez!)</i>
                                <br/>
                                A képzés során törekszem arra, hogy az elméleti tudás és a 
                                gyakorlati ismeretek egyensúlyba kerüljenek. Az egyéni, 
                                saját tempódban tudunk haladni, és bármennyiszer átismételhetünk
                                egy adott anyagot. <b>Akár kezdőként, nulla tudással is belevághatsz.</b>
                            </p>

                            <ul className="list">
                                <li>
                                    <FontAwesomeIcon className="mr5 color-secondary"
                                    icon="fa-solid fa-circle-check" />
                                    <b>Gyakorlati példák:</b> A kurzus során számos, valós életből vett példát fogunk 
                                    használni, hogy a résztvevőknek lehetőségük legyen a gyakorlatban 
                                    alkalmazni az elméleti tudást.
                                </li>
                                <li>
                                    <FontAwesomeIcon className="mr5 color-secondary"
                                    icon="fa-solid fa-circle-check" />
                                    <b>Házi feladatok és gyakorlási lehetőségek:</b> Biztosítok házi 
                                    feladatokat és gyakorlási lehetőségeket, hogy a résztvevők elmélyíthessék a 
                                    tanultakat és gyakorolhassák az újonnan szerzett készségeiket.
                                </li>
                                <li>
                                    <FontAwesomeIcon className="mr5 color-secondary"
                                    icon="fa-solid fa-circle-check" />
                                    <b>Portfolió elemek:</b> A kurzus résztvevői számára lehetőség nyílik portfolió 
                                    elemek létrehozására, amelyek segíthetnek nekik munkát szerezni vagy 
                                    fejleszteni jelenlegi pozíciójukat.
                                </li>
                                <li>
                                    <FontAwesomeIcon className="mr5 color-secondary"
                                    icon="fa-solid fa-circle-check" />
                                    <b>Rugalmas időbeosztás:</b> Az órák időpontjait rugalmasan lehet megállapítani, 
                                    hogy azok a résztvevők beosztásához igazodjanak. Ha esetleg szüneteltetnéd 
                                    a tanulást egy-két hétre, nem gond.
                                </li>
                            </ul>

                            <div className="mb20">
                                <h1>Az árakról</h1>
                                <p>
                                    Nem szükséges előre fizetni, a szolgáltatás <b>óradíjas</b>,
                                    ráadásul <b>bármikor abbahagyhatod a képzést!</b>
                                </p>
                                <b>1 fő esetén 6000 Ft/óra</b> <br/>
                                <b>2 fő esetén 5500 Ft/óra</b> <br/>
                                <b>3 vagy több fő esetén 5000 Ft/óra</b>
                            </div>

                            <div className="box-white p20">
                                <h2 className="text-center">Érdeklődés a kurzusról</h2>
                                <h4 className="text-center">(Elköteleződés nélkül!)</h4>

                                <ContactForm subject={"Webfejlesztés kurzus"}/>
                            </div>
                        </div>
                    </div>
                    <div className="p15 col-lg-6 col-md-6">
                        <div className="box p15 mobile-center" id="elmelet">
                            <h1>A webfejlesztésről</h1>
                            <p>
                                Az alábbi pár bekezdésben valószínűleg olyan kifejezéseket 
                                és zsargont fogsz olvasni, amelyet nem értesz meg teljes mértékben.
                                Éppen ezért megpróbálok segíteni neked eligazodni a webfejlesztés 
                                világában. A webfejlesztés két jól elkülöníthető területre osztható szét.
                                Ezek a <b>frontend (kliens oldal)</b> és a <b>backend (szerver oldal)</b>.
                            </p>

                            <h2 id="frontend">Mi a frontend? (kliens oldali kód)</h2>
                            <p>
                                <Img bordered={false} width="150" height="150" bg=""
                                side="left" src={require("../images/webpage.png")}/>
                                A frontend kód felelős a felhasználói interakciókért, mint például a kattintások és görgetések
                                kezelése, valamint a UI (User Interface/felhasználói interfész) kialakítása.
                                Ez az, ami szabad szemmel látható egy weblapon.
                                A frontend kódot a böngésző futtatja le, tehát a felhasználó számítógépén hajtódik végre.
                                A <b>JavaScript</b>, a <b>HTML</b> és a <b>CSS</b> kódot is a böngésző kezeli.
                                A kliens általában véve egy hálózati kommunikációra alkalmas program,
                                ami a webfejlesztésben legtöbbször a <b>böngésző</b>.
                            </p>

                            <div className="clear"></div>

                            <h2 id="backend">Mi a backend? (szerver oldali kód)</h2>
                            <p>
                                <Img bordered={false} width="150" height="150" bg=""
                                side="right" src={require("../images/server.png")}/>
                                A <b>backend</b> csatlakozik az <b>adatbázishoz</b>, azonosítja a <b>felhasználókat</b>,
                                oszt ki <b>jogosultságokat</b>, kezel <b>fájlokat</b> és úgy általában véve ő 
                                felelős az <b>üzleti logikáért</b>. A backendnek, azaz másnéven
                                a <b>szerveroldali kódnak</b> nem csak hatékonynak, hanem <b>biztonságosnak</b>, 
                                is kell lennie. Így egy backend fejlesztőnek nem csupán a 
                                teljesítmény-optimalizálás, hanem a <b>felhasználói adatok védelme</b> is a feladata.
                                A backend folyamatok a háttérben,
                                a <b>webszerveren</b> futnak. A webszerver egy számítógép,
                                amit hálózaton (interneten) keresztül ér el a kliens, és weboldalakat, valamint 
                                az azokhoz tartozó szolgáltatásokat kezel.
                            </p>

                            <div className="clear"></div>

                            <h2>HTML (a frontend része)</h2>
                            <p>
                                A <b>HTML</b> (HyperText Markup Language) egy úgynevezett <b>leíró nyelv</b>, 
                                amelyet weboldalak szerkezetének 
                                kialakításához fejlesztettek ki. Lényegében azt mondja meg, hogy 
                                milyen elemek legyenek egy weboldalon. <br/>
                                A technológia használata egyszerű, de ismerete elengedhetetlen ahhoz,
                                hogy webfejlesztővé válj.
                            </p>

                            <h2>CSS (a frontend része)</h2>
                            <p>
                                A <b>CSS</b> (Cascading Style Sheets) segítségével a weblapok 
                                stíluskészletét adhatjuk meg. Színeket, formákat, elrendezéseket
                                rendelhetünk hozzá a weboldalhoz.
                            </p>

                            <h2>SCSS (a frontend része)</h2>
                            <p>
                                A <b>Sass</b> (Syntactically Awesome Stylesheets) egy stíluslap nyelv, 
                                amely kiterjeszti a CSS-t, lehetővé téve a változók, beágyazott 
                                szabályok, mixinek és függvények használatát. Segítségével a CSS 
                                kód strukturáltabbá, könnyebben karbantarthatóvá és újrahasznosíthatóvá válik. 
                                A Sass két szintaxisban érhető el: az indentált (behúzás alapú) 
                                szintaxis és az SCSS (Sassy CSS), amely a hagyományos CSS 
                                szintaxisra épül.
                            </p>

                            <h2>React (a frontend része)</h2>
                            <p>
                                A <b>React</b> egy JavaScript keretrendszer, amelyet felhasználói felületek építésére
                                terveztek. A komponensalapú megközelítése lehetővé teszi az újrahasznosítható, önálló 
                                modulok létrehozását, amelyek kezelik saját állapotukat és megjelenésüket. 
                                A React virtuális DOM-ot használ, amely gyorsabb frissítéseket és jobb 
                                teljesítményt biztosít a felhasználói interakciók során. Széles körben 
                                használják modern webalkalmazások fejlesztéséhez, mivel egyszerűbbé és 
                                hatékonyabbá teszi az interaktív és dinamikus felületek létrehozását.
                            </p>

                            <h2>NodeJS (a backend része)</h2>
                            <p>
                                A <b>Node.js</b> egy olyan futtatókörnyezet, amely lehetővé teszi a JavaScript 
                                használatát szerveroldalon is, nem csak a böngészőkben.
                                Ezáltal teljes webalkalmazásokat lehet fejleszteni ugyanazzal a nyelvvel, 
                                mind a kliens-, mind a szerveroldalon. Emellett gyors és hatékony, 
                                mivel aszinkron (non-blocking), eseményvezérelt architektúrát használ, ami különösen 
                                alkalmas nagy teljesítményű, valós idejű alkalmazásokhoz.
                            </p>

                            <h2>MySQL (a backend része)</h2>
                            <p>
                                A <b>MySQL</b> egy népszerű nyílt forráskódú adatbázis-kezelő rendszer, amely lehetővé 
                                teszi adatok tárolását, kezelését és lekérdezését strukturált formában. 
                                Gyakran használják webalkalmazásokban, hogy dinamikus tartalmat szolgáltassanak, 
                                például felhasználói profilokat, bejegyzéseket és egyéb adatokat. Az SQL 
                                (Structured Query Language) nyelvet használja az adatok kezeléséhez, amely egyszerű és 
                                hatékony lekérdezéseket és műveleteket tesz lehetővé. Megbízhatósága és teljesítménye 
                                miatt széles körben alkalmazzák kis és nagy projektekben egyaránt.
                            </p>

                        </div>

                    </div>
                    
                </div>
            </div>

            <ContactLine bgcolor="bg-dark-grey"/>
        </>
    );
}

export default WebDevCourse;