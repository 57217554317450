import { cBaseUrl } from "../app/url";

function Service(props) {
    return (
        <div className="col-lg-4 col-md-4 p15">
            <div className="tech-logo box bg-white p15">
                <div className="tech-logo-img">
                    <img src={props.img}></img>
                </div>

                <h3>{props.serviceName}</h3>

                <p>
                    {props.desc}
                </p>
            </div>
        </div>
    )
}

export default Service;