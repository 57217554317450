import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ContactLine(props) {
    const classes = "contact-line " + props.bgcolor;

    return(
        <>
            <div className={classes}>
                <div className="mr15">
                    <FontAwesomeIcon icon="fa-solid fa-mobile-screen" /> 
                    <a className="color-white text-shadow none-underline" href="tel:+3670 305 0928"> +3670 305 0928</a>
                </div>
                <div>
                    <FontAwesomeIcon icon="fa-solid fa-square-envelope" /> 
                    <a className="color-white text-shadow none-underline" href="mailto:kovacs.oliver1989@gmail.com"> kovacs.oliver1989@gmail.com</a>
                </div>
            </div>
        </>
    );
}

export default ContactLine;